<template>
  <div class="new-project">
    <h3 class="section-title">LASTEST PROJECTS</h3>
    <h1 class="section-sub-title">Latest projects we did</h1>
    <div
      class="new-project-card-container"
      data-aos="zoom-in"
      data-aos-duration="2000"
    >
      <swiper
        :slidesPerView="3"
        :spaceBetween="30"
        :autoplay="true"
        :loopFillGroupWithBlank="true"
        :pagination="{
          clickable: true,
        }"
        :breakpoints="breakings"
        :navigation="false"
        :modules="modules"
        class="mySwiper"
      >
        <swiper-slide v-for="(item, index) in projectsData" :key="index">
          <div class="new-project-card">
            <img :src="item?.fistImage?.asset?.url" alt="" />
            <h4>{{ item.name }}</h4>
            <p v-if="item.description">{{ item.description }}</p>
            <p v-else>-</p>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="new-project-btn">
      <base-button
        to="/projects"
        text="All Projects"
        onBorder="20px"
      ></base-button>
    </div>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

import "./style.css";

// import required modules
import { Pagination, Navigation, Autoplay } from "swiper";
import BaseButton from "./BaseButton.vue";
import sanityClient from "../client";

export default {
  name: "NewProjects",
  components: {
    Swiper,
    SwiperSlide,
    BaseButton,
  },
  data() {
    return {
      modules: [Pagination, Navigation, Autoplay],
      breakings: {
        200: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        600: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1000: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      },
      newProjects: [],
      projectsData: [],
    };
  },
  created() {
    const query = `*[_type == "projects"][0..5]{
        description,
        name,
        fistImage{asset->{_id,url}},
        secondImage{asset->{_id,url}},
        thirdImage{asset->{_id,url}},
        fourthImage{asset->{_id,url}},
        fithImage{asset->{_id,url}},
    }`;

    sanityClient.fetch(query).then((res) => {
      res.forEach((item) => {
        this.projectsData.push({
          ...item,
          isShowViewMore: false,
        });
      });
    });
  },
};
</script>

<style scoped>
.new-project {
  padding: 40px 20px;
}
.new-project-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
}
.new-project-card {
  box-shadow: 2px 3px 6px #d5d5d5;
  width: 270px;
  margin: 20px 10px;
  text-align: center;
  border-radius: 4px;
  overflow: hidden;
  padding-bottom: 10px;
  /* transition: all 0.5s ease-in-out; */
}

/* .new-project-card:hover {
  transform: scale(1.04);
} */

.swiper-slide {
  margin-bottom: 20px;
}

.new-project-card img {
  width: 100%;
  height: 200px;
}

.new-project-card h4 {
  font-size: 15px;
  font-weight: 400;
  margin: 5px 0;
  text-transform: uppercase;
  color: #4d4d4d;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.new-project-card p {
  font-weight: 300;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  margin: 0 15px 5px;
  color: #4d4d4d;
}

.new-project-btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 700px) {
  .new-project-card-container {
    width: 100%;
  }
}
</style>