<template>
  <div class="our-projects">
    <div class="project-cover-img">
      <img src="../assets/NRCltd17.jpg" alt="" />
      <h1>All Projects</h1>
    </div>
    <h2 class="other-section-title">Output of Our Works</h2>
    <div class="app-default-underline"></div>
    <div class="data-loader-container" v-if="isLoadingProjects">
      <main-loader></main-loader>
    </div>
    <div class="projects-container">
      <div
        class="projects-card"
        data-aos="fade-down"
        data-aos-duration="2000"
        v-for="(item, index) in projectsData"
        :key="index"
      >
        <img :src="item?.fistImage?.asset?.url" alt="" />
        <h4 v-if="!item.isShowViewMore">{{ item.name }}</h4>
        <div
          class="view-details-btn"
          @mouseover="item.isShowViewMore = true"
          @mouseleave="item.isShowViewMore = false"
        >
          <button v-if="item.isShowViewMore" @click="viewMoreDetails(item)">
            View details
          </button>
        </div>
      </div>
    </div>
    <div class="load-more-projects">
      <!-- <base-button
        :action="loadMoreProjects"
        text="Load more projects"
        onBorder="20px"
      ></base-button> -->
    </div>
    <Transition name="fade">
      <div class="project-details-modal" v-show="isOpenModal">
        <modal
          :closeModal="closeModal"
          :selectedProject="selectedProject"
        ></modal>
      </div>
    </Transition>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import Modal from "@/components/Modal.vue";
import sanityClient from "../client.js";
import MainLoader from "@/components/MainLoader.vue";

export default {
  components: { BaseButton, Modal, MainLoader },
  data() {
    return {
      isOpenModal: false,
      selectedProject: {},
      projectsData: [],
      isLoadingProjects: false,
    };
  },
  methods: {
    loadMoreProjects() {},
    viewMoreDetails(item) {
      this.selectedProject = item;
      this.isOpenModal = true;
    },
    closeModal() {
      this.isOpenModal = false;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    const query = `*[_type == "projects"]{
        description,
        name,
        fistImage{asset->{_id,url}},
        secondImage{asset->{_id,url}},
        thirdImage{asset->{_id,url}},
        fourthImage{asset->{_id,url}},
        fithImage{asset->{_id,url}},
    }`;
    this.isLoadingProjects = true;
    sanityClient.fetch(query).then((res) => {
      this.isLoadingProjects = false;
      res.forEach((item) => {
        this.projectsData.push({
          ...item,
          isShowViewMore: false,
        });
      });
    });
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.our-projects {
  padding-top: 80px;
}

.project-cover-img {
  width: 100%;
}

.project-cover-img img {
  width: 100%;
  height: 25vh;
}

.project-cover-img h1 {
  position: absolute;
  top: 17%;
  left: 40px;
  color: white;
  -webkit-text-stroke: 1px black;
  font-size: 60px;
}

.other-section-title {
  margin-top: 30px;
}

.app-default-underline {
  margin-bottom: 20px;
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 80%;
  margin: 0 auto 30px;
}

.projects-card {
  width: 100%;
  height: 250px;
  cursor: pointer;
  position: relative;
}

.projects-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.projects-card h4 {
  position: absolute;
  bottom: 40px;
  left: 20px;
  color: white;
  text-transform: uppercase;
  font-weight: 200;
  z-index: 50;
}

.view-details-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.view-details-btn:hover {
  background: rgb(0, 0, 0, 0.7);
  z-index: 50;
}

.view-details-btn button {
  background: transparent;
  color: var(--primary-color);
  text-decoration: underline;
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 50;
}

.projects-card-contant {
  padding: 10px 10px 0;
}

.load-more-projects {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.data-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 70px auto;
}

@media (max-width: 600px) {
  .project-cover-img h1 {
    font-size: 50px;
    top: 20%;
  }
}
@media (max-width: 460px) {
  .project-cover-img h1 {
    font-size: 40px;
    top: 22%;
  }
}

@media (max-width: 400px) {
  .project-cover-img h1 {
    font-size: 30px;
    -webkit-text-stroke: 1px white;
    top: 22%;
  }
}
</style>